import { createSlice } from "@reduxjs/toolkit";
import TokenService from "../../../services/tokenService";
import jwtDecode from "jwt-decode";

const today = new Date();
const getUserUuid=()=>{
  const token = TokenService.getLocalAccessToken();
  if(!token) return null;
  const {user_uuid} =jwtDecode(token); 
  return user_uuid;
};


export const dailyAttendanceSlice = createSlice({
  name: "stateDailyAttendance",
  initialState: {
   selectUser:getUserUuid(),
   selectYearMonth:`${today.getFullYear()}${today.getMonth() + 1}`
  },
  reducers: {
    setSelectUser: (state, action) => {
      state.selectUser = action.payload.user_uuid;
    },

    setSelectYearMonth: (state, action) => {
      state.selectYearMonth = action.payload.selectYearMonth;
    },

    initSelectUser: (state, action) => {
      state.selectUser = getUserUuid();
    },


  },
});

export const {
  setSelectUser,
  setSelectYearMonth,
  initSelectUser
} = dailyAttendanceSlice.actions;

export default dailyAttendanceSlice.reducer;
