import { createSlice } from "@reduxjs/toolkit";

export const statePhotobookSlice = createSlice({
  name: "statePhotobook",
  initialState: {
    infoSwap: {
        itemSource: {
          file_uuid: null,
          index: null
        },
        itemTarget:{
          file_uuid: null,
          index: null
        }
    },
    listFileUuidUpload: []
  },
  reducers: {
    setInfoSwap: (state, action) => {
      state.infoSwap = action.payload;
    },
    setListFileUuidUpload: (state, action) => {
      state.listFileUuidUpload = action.payload
    }
  }
});

export const {
    setInfoSwap,
    setListFileUuidUpload
} = statePhotobookSlice.actions;

export default statePhotobookSlice.reducer;
